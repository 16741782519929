<template>
  <a-card>
    <a-page-header
      v-if="queryParam.id"
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="抄表记录"
      @back="() => $router.go(-1)">
    </a-page-header>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 1000 }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <div slot="money_render_4" slot-scope="text">
        {{ (text / 100).toFixed(4) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  electricity_meter_record_list,
  electricity_meter_record_create,
  electricity_meter_record_delete
} from '@/api/electricity-meter'
import { Dictionaries } from '@/common/AllConstant'

import { electricity_bill_create } from '@/api/electricity'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      key: 1,
      loading: false,
      confirmLoading: false,
      editconfirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      editvisible: false,
      mdl: {},
      editmdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: { },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '电表编号',
          dataIndex: 'meter_number',
          width: 150,
          ellipsis: true
        },
        {
          title: '批次',
          dataIndex: 'batch',
          width: 150,
          ellipsis: true
        },
        {
          title: '读表数',
          ellipsis: true,
          width: 150,
          dataIndex: 'value'
        },
        {
          title: '上期读表数',
          ellipsis: true,
          width: 150,
          dataIndex: 'last_value'
        },
        {
          title: '单价',
          ellipsis: true,
          dataIndex: 'price',
          width: 150,
          scopedSlots: { customRender: 'money_render_4' }
        },
        {
          title: '总价',
          ellipsis: true,
          dataIndex: 'amount',
          width: 150,
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '时间',
          width: 200,
          dataIndex: 'create_time'
        }
        // ,
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   width: '150px',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return electricity_meter_record_list({ bill_id: this.$route.params.id })
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
    const id = this.$route.params.id
    if (id) {
      this.queryParam.id = id
    }
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    electricity_bill_c () {
      this.handleEdit()
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEditCancel () {
      this.editvisible = false
      const form = this.$refs.editModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.key++
      this.editmdl = record
      this.editvisible = true
    },
    handleDelet (record) {
      electricity_meter_record_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.keys = undefined
          // 新增
          var res = values.data
          res = res.replace('，', ',')
          res = res.split('\n')
          res = res.map((item) => {
            return item.split(',')
          })
          values.data = res
          electricity_meter_record_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleEditOk () {
      const form = this.$refs.editModal.form
      this.editconfirmLoading = true

      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          electricity_bill_create(values).then(res => {
            this.editvisible = false
            this.editconfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('删除成功------')
          }).finally(() => {
            this.editconfirmLoading = false
          })
        } else {
          this.editconfirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
